/* eslint-disable react/no-unknown-property */
import "../styles/globals.css";
import "react-multi-carousel/lib/styles.css";
import "font-awesome/css/font-awesome.css";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
    </QueryClientProvider>
  );
}

export default MyApp;
